<template>
	<div>
		<div class="toolBar">
			<el-button type="primary" plain size="small" @click="searchUser">查询</el-button>
			<el-button type="primary" plain size="small" @click="addUser">新增</el-button>
			<el-button  size="small" class="fr" @click="refresh">刷新</el-button>
		</div>

		<el-card class="box-card" style="margin-top: 10px">
			<el-table :data="tableData"  size='small' stripe :height='tableHeight'>
				<el-table-column type="index" align="center" label='序号'></el-table-column>
				<el-table-column prop="name" label="姓名" ></el-table-column>
				<el-table-column prop="phone" label="手机号" ></el-table-column>
				<el-table-column prop="idNumber" label="身份证号"  :formatter="formatterFromIdNumber"></el-table-column>
				<el-table-column prop="fromType" label="来源" :formatter="formatterFromType"></el-table-column>
				<el-table-column prop="registerTime" label="注册时间"></el-table-column>
				<el-table-column prop="status" label="状态"  :formatter="formatterStatus"></el-table-column>
				<el-table-column  label="操作" align="center">
					<template slot-scope="scope">
						<!--<el-button size="mini" type="text" @click="getUser(scope.$index,scope.row)">详情</el-button>-->
						<el-button size="mini" type="text" @click="editUser(scope.$index,scope.row)">编辑</el-button>
						<el-button size="mini" type="text" @click="editPwd(scope.$index,scope.row)">重置密码</el-button>
						<el-button size="mini" v-if="scope.row.status!=0" type="text" style="color: red" @click="lockUser(scope.$index,scope.row)">锁定</el-button>
						<el-button size="mini" v-if="scope.row.status==0" type="text" style="color: red" @click="openlock(scope.$index,scope.row)">解锁</el-button>
						<el-button size="mini" type="text" style="color: red" :disabled="scope.row.status==-1" @click="deleteUser(scope.$index,scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
		</el-card>

		<el-dialog v-dialogDrag :visible.sync="searchDialog" width="40%" title='查询'>
			<el-form label-width="100px" ref='searchForm' :model='searchForm' size='small'>
				<el-row>
					<el-col :sm="24">
						<el-form-item label="姓名">
							<el-input v-model="searchForm.name" clearable placeholder="姓名"></el-input>
						</el-form-item>
					</el-col>
					<el-col :sm="24">
						<el-form-item label="手机号">
							<el-input :maxlength=11 v-model="searchForm.phone" clearable placeholder="手机号"></el-input>
						</el-form-item>
					</el-col>
					<el-col :sm="24">
						<el-form-item label="身份证号">
							<el-input :maxlength=18 v-model="searchForm.idNumber" clearable placeholder="身份证号"></el-input>
						</el-form-item>
					</el-col>
					<el-col :sm="24">
						<el-form-item label="来源">
							<el-select v-model="searchForm.fromType " clearable style="width:100%">
								<el-option v-for="item in global.DictionaryData['userSource']" :label="item.label" :value="item.value" :key="item.value"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :sm="24">
						<el-form-item label="状态">
							<el-select v-model="searchForm.status " clearable style="width:100%">
								<el-option v-for="item in global.DictionaryData['userStatus']" :label="item.label" :value="item.value" :key="item.value"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :sm="24">
						<el-form-item label="注册时间">
							<el-date-picker class='maxWidth' style="width: 100%" value-format="yyyy-MM-dd" @change='dataChange' v-model="dateValue" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions">
							</el-date-picker>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="searchDialog = false" size='small'>取消</el-button>
				<el-button @click="onSearch" type="primary" size='mini'>确定</el-button>
			</div>
		</el-dialog>

		<el-dialog v-dialogDrag :visible.sync="addUserDialog" width="40%" title='新增'>
			<el-form label-width="100px" ref='addUserForm' :rules="addUserFormRules" :model='addUserForm' size='small'>
				<el-col :sm="24">
					<el-form-item label="姓名" prop="name">
						<el-input v-model="addUserForm.name" clearable placeholder="请输入姓名"></el-input>
					</el-form-item>
				</el-col>
				<el-col :sm="24">
					<el-form-item label="手机号" prop="phone">
						<el-input :maxlength=11 v-model="addUserForm.phone" clearable placeholder="11位手机号"></el-input>
					</el-form-item>
				</el-col>
				<!--<el-col :sm="12">
					<el-form-item label="验证码">
						<el-input v-model="addUserForm.captcha" clearable placeholder="验证码"></el-input>
					</el-form-item>
				</el-col>
				<el-col :sm="12">
					<el-button type="warning" :disabled="btnabled" @click="send" v-text="btntext" size='mini'></el-button>
				</el-col>-->
				<el-col :sm="24">
					<el-form-item label="登录密码" prop="pwd">
						<el-input v-model="addUserForm.pwd" clearable placeholder="字母+数字，6-32位"></el-input>
					</el-form-item>
				</el-col>
				<el-col :sm="24">
					<el-form-item label="身份证号">
						<el-input :maxlength=18 v-model="addUserForm.idNumber" clearable placeholder="请输入身份证号"></el-input>
					</el-form-item>
				</el-col>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="addUserDialog = false" size='small'>取消</el-button>
				<el-button @click="addSuccess" type="primary" size='mini'>确定</el-button>
			</div>
		</el-dialog>

		<el-dialog v-dialogDrag :visible.sync="userFromPCDialog" width="40%" title="修改用户信息">
		<el-form label-width="100px" ref='getUserForm' :model='getUserForm' size='small'>
			<el-row>
				<el-col :sm="24">
					<el-form-item label="用户姓名：">
						<el-input v-model="getUserForm.name" clearable placeholder="姓名"></el-input>
					</el-form-item>
				</el-col>
				<el-col :sm="24">
					<el-form-item label="身份证号：">
						<el-input :maxlength=18 v-model="getUserForm.idNumber" clearable placeholder="身份证号"></el-input>
					</el-form-item>
				</el-col>
				<el-col :sm="24">
					<el-form-item label="密码：">
						<el-input :maxlength=18 v-model="getUserForm.pwd" clearable placeholder="密码"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
		</el-form>
		<div slot="footer" class="dialog-footer">
			<el-button @click="userFromPCDialog = false" size='small'>取消</el-button>
			<el-button @click="success" type="primary" size='mini'>确定</el-button>
		</div>
	</el-dialog>

		<el-dialog v-dialogDrag :visible.sync="userFromDialog" width="40%" title="重置密码">
			<el-form label-width="100px" ref='editUserForm' :model='editUserForm' size='small' :rules="editUserFormRules">
				<el-row>
					<el-col :sm="24">
						<el-form-item label="用户姓名：">
							<el-input v-model="editUserForm.name" clearable placeholder="姓名" :disabled="true"></el-input>
						</el-form-item>
					</el-col>
					<el-col :sm="24">
						<el-form-item label="密码：" prop="pwd">
							<el-input :maxlength=18 v-model="editUserForm.pwd" clearable placeholder="密码"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="userFromDialog = false" size='small'>取消</el-button>
				<el-button @click="handleEdit" type="primary" size='mini'>确定</el-button>
			</div>
		</el-dialog>

		<div class="toolBar clearfix" style="margin-top: 10px">
			<pagi-nation :page='userPage' @sizeChange='userSizeChange' @currentChange='userCurrentChange'></pagi-nation>
		</div>
	</div>
</template>

<script>
	import uploadImg from '@/components/uploadImg/uploadImg'

	var validPhone=(rule, value,callback)=>{
		if (!value){
			callback(new Error('请输入电话号码'))
		}else  if (!isvalidPhone(value)){
			callback(new Error('请输入正确的11位手机号码'))
		}else {
			callback()
		}
	}

	class SearchForm {
		constructor() {
			this.name = null;
			this.number = null;
			this.idCard = null;
			this.from = null;
			this.status = null;
			this.beginDate=null;
			this.endDate=null;
		}
		reset() {
			this.constructor();
		}
	}

	class addForm{
		constructor() {
			this.name = null;
			this.phone = null;
			this.pwd = null;
			this.idNumber = null;
			this.captcha=null;
		}
		reset() {
			this.constructor();
		}
	}

	class UserForm{
		constructor() {
			this.id = null;
			this.name = null;
			this.idNumber = null;
			this.preview=null;
			this.headUrl=null;
			this.pwd=null;
		}
		reset() {
			this.constructor();
		}
	}


	export default {
		components: {uploadImg},
		data(){
			return{
				edit:false,
				btnabled:false,
				btntext:"获取验证码",
				dateValue: null,
				tableData:[],
				tableHeight: document.documentElement.clientHeight - 288,
				userPage:new this.Page(),
				searchDialog:false,
				addUserDialog:false,
				userFromPCDialog:false,
				userFromSCDialog:false,
				userFromOADialog:false,
				uploadDlgVisibleB:false,
				userFromDialog:false,
				getUserForm:new UserForm(),
				searchForm:new SearchForm(),
				addUserForm:new addForm(),
				editUserForm:new UserForm(),
				addUserFormRules: {
					name: [{required: true, message: '姓名是必须的', trigger: 'blur'}],
					phone: [{required: true, trigger: 'blur',validator: validPhone}],
					idNumber: [{required: true, message: '请填写证件号码', trigger: 'blur' },
						{pattern: /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/,
							message: '证件号码格式有误！', trigger: 'blur'}],
					pwd: [{required: true, message: '密码是必须的', trigger: 'blur'},
						{min: 6, max: 32, message: '长度在 6 到 32 个字符'},
						{pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,32}$/, message: '字母+数字,长度6-32个字符'}],
				},
				editUserFormRules:{
					pwd: [{required: true, message: '密码是必须的', trigger: 'blur'},
						{min: 6, max: 32, message: '长度在 6 到 32 个字符'},
						{pattern: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,32}$/, message: '字母+数字,长度6-32个字符'}],
				},
				pickerOptions: {
					shortcuts: [{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}]
				},
			}
		},
        computed: {

        },
		methods:{
			formatterFromIdNumber(row,column){
				if(row.idNumber){
					return row.idNumber
				}else {
					return "暂无"
				}
			},
			formatterFromType(row,column){
				return this.getname('userSource',row.fromType);
			},
			formatterStatus(row,column){
				return this.getname('userStatus',row.status);
			},
			resetForm(formName) {
				if(this.$refs[formName] !== undefined) {
					this.$refs[formName].resetFields();
				}
			},
			uploadImgB(e){
				this.uploadDlgVisibleB=true;
				this.$refs['uploadImgB'].uploadImg(e);
			},
			resetForm(formName) {
				if(this.$refs[formName] !== undefined) {
					this.$refs[formName].resetFields();
				}
			},
			hideB(){
				this.$refs['uploadInputB'].value=null;
				this.uploadDlgVisibleB=false;
			},
			handleSuccessImgB(data){
				this.$message.success('上传成功！');
				this.getUserForm.headUrl=IMAGE_BASE+data;
				this.getUserForm.preview=data;
				this.hideB();
			},
			refresh(){
				this.getuserList();
			},
			async success(){
				let param={
					id:this.getUserForm.id,
					name:this.getUserForm.name,
					idNumber:this.getUserForm.idNumber,
					pwd:this.getUserForm.pwd,
					headUrl:this.getUserForm.headUrl
				};
				if(param.idNumber){
					let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
					if (!reg.test(param.idNumber)) {
						this.$message.warning('请输入合法身份证！');
						return false;
					}
				}
				let res = await this.$http.post('/sys/updateUser', param);
				if(res.state){
					this.$message.success('修改成功！');
					this.userFromPCDialog = false;
				}else{
					console.log(res.msg)
				}
				this.getuserList();
			},
			async getuserList(){
				let paramObj={
					limit:this.userPage.pageSize,
					offSet:(this.userPage.currentPage-1)*this.userPage.pageSize,
				};
				paramObj=Object.assign(paramObj,this.searchForm)
				let res = await this.$http.post('/sys/userList',paramObj);
				if(res.state){
					this.tableData=res.data.list;
					this.userPage.total=res.data.totalCount;
				}
			},
			searchUser(){
				this.searchDialog=true;
			},
			addUser(){
				this.resetForm('addUserForm');
				this.addUserForm.reset();
				this.addUserDialog=true;
			},
			addSuccess(){
				this.$refs['addUserForm'].validate((valid) => {
					if (valid) {//表单验证通过
						this.addOrUser();
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			async addOrUser(){
				if(this.addUserForm.idNumber) {
					let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
					if (!reg.test(this.addUserForm.idNumber)) {
						this.$message.warning('请输入合法身份证！');
						return false;
					}
				}
				let res=await this.$http.post('/sys/addUser',this.addUserForm);
				if(res.state){
					this.$message.success('添加成功！');
					this.addUserDialog=false;
				}else {
					console.log(res.msg)
				}
				this.getuserList();
			},
			onSearch(){
				this.searchDialog=false;
				this.getuserList();
			},
			dataChange(val) {
				if(!val){
					this.dateValue=[];
				}
				this.searchForm.beginDate=this.dateValue[0];
				this.searchForm.endDate=this.dateValue[1];
			},
			async getUserInfo(id){
				let res = await this.$http.post('/sys/getUser',{id});
				if(res.state){
					this.getUserForm=res.data;
				}
			},
			editUser(index,row){
				this.getUserForm.reset();
				Object.assign(this.getUserForm,row)
				this.userFromPCDialog=true;
			},
			editPwd(index,row){
				this.resetForm('editUserForm')
				this.editUserForm.reset();
				Object.assign(this.editUserForm,row)
				this.userFromDialog=true;
			},
			handleEdit(){
				this.$refs['editUserForm'].validate((valid) => {
					if (valid) {//表单验证通过
						this.handleSuccess();
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			async handleSuccess(){
				let param={
					userId:this.editUserForm.id,
					pwd:this.editUserForm.pwd,
				}
				this.userFromDialog=false;
				let res=await this.$http.post('/sys/resetPassword',param);
				if(res.state){
					this.$message({type: 'success', message: '重置成功'});
				}
			},
			lockUser(index,row){
				this.$confirm('锁定后，用户将无法访问所有平台，是否继续？', '提示', {confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'}).then(() => {
					this.lock(row.id);
				}).catch(() => {
					this.$message({type: 'info', message: '取消锁定'});
				});
			},
			async openlock(index,row){
				this.$confirm('该用户操作可能存在异常行为已被锁定，确认解锁？', '提示', {confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'}).then(() => {
					this.openUserLock(row.id);
				}).catch(() => {
					this.$message({type: 'info', message: '取消解锁'});
				});
			},
			async openUserLock(id){
				let param={id:id, status:1,}
				let res=await this.$http.post('/sys/updUserStatus',param);
				if(res.state){
					this.$message({type: 'success', message: '解锁成功'});
				}
				this.getuserList();
			},
			deleteUser(index,row){
				this.$confirm('删除后，用户将无法访问所有平台，是否继续？', '提示', {confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning'}).then(() => {
					this.delete(row.id);
				}).catch(() => {
					this.$message({type: 'info', message: '取消删除'});
				});
			},
			async lock(id){
				let param={id:id, status:0}
				let res=await this.$http.post('/sys/updUserStatus',param);
				if(res.state){
					this.$message({type: 'success', message: '已锁定!'});
				}
				this.getuserList();
			},
			async delete(id){
				let param={id:id, status:-1}
				let res=await this.$http.post('/sys/updUserStatus',param);
				if(res.state){
					this.$message({type: 'success', message: '已删除!'});
				}
				this.getuserList();
			},
			userSizeChange(val){
				this.userPage.currentPage=1;
				this.userPage.pageSize=val;
				this.getuserList();
			},
			userCurrentChange(val){
				this.userPage.currentPage=val;
				this.getuserList();
			}
		},
		created(){
			let endTime=this.getDate();
			let startTime=this.getStartDateOfMonth();
			this.dateValue=[startTime,endTime];
			this.searchForm.beginDate=this.dateValue[0];
			this.searchForm.endDate=this.dateValue[1];
		},
		mounted() {
			/*this.getoptions('userSource');
			this.getoptions('userStatus');*/
			this.getuserList();
			window.onresize = () => {
				window.fullHeight = document.documentElement.clientHeight
				this.tableHeight = window.fullHeight - 283;
			}
		}
	}

	function isvalidPhone(str) {
		const reg = /^1(2|3|4|5|6|7|8|9)\d{9}$/
		return reg.test(str)
	}
</script>

<style scoped>
</style>